import React from "react";
import styled from "styled-components";

const Container = styled.div`
  background: #faeebe;
  border: 1px solid #ecc36b;
  padding: 5px 0;
  text-align: center;
  color: black;
`;

const MaintenanceBanner = () => {
  return (
    <Container>
      <p>
        Please note that you will not be able to access the portal on 17 Mar’21
        (Wed), from 1 to 7pm (GMT+8) as we will be upgrading our servers to
        improve performance.
      </p>
    </Container>
  );
};

export default MaintenanceBanner;
